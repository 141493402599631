import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth"
// import { getFirestore } from 'firebase/firestore'
import { getAnalytics, setUserProperties } from "firebase/analytics";

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const app = initializeApp(config.public.firebase);

  const analytics = getAnalytics(app);
  setUserProperties(analytics, { app_id: useRuntimeConfig().public.appId });

  // const auth = getAuth(app);
  // nuxtApp.vueApp.provide('auth', auth);
  // nuxtApp.provide('auth', auth);

  // const firestore = getFirestore(app);
  // nuxtApp.vueApp.provide('firestore', firestore);
  // nuxtApp.provide('firestore', firestore);
});
