export default defineNuxtPlugin(() => {
  const bus = useEventBus<string>("main");

  const listener = (event: string) => {
    if (event == "menu:open") {
      // document.documentElement.classList.add("has-overlay");
      document.getElementById("__nuxt")?.classList.add("has-overlay");
    }
    if (event == "menu:close") {
      // document.documentElement.classList.remove("has-overlay");
      document.getElementById("__nuxt")?.classList.remove("has-overlay");
    }
  };

  bus.on(listener);
});
