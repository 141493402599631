import { Preferences } from "@capacitor/preferences";

export default defineNuxtRouteMiddleware(async (from) => {
  const welcomeCompleted = await Preferences.get({ key: "welcomeCompleted" });

  if (!welcomeCompleted.value && from.name !== "welcome") {
    return navigateTo("/welcome");
  }

  return;
});
