import { default as chat8unszGOD0IMeta } from "/home/ploi/dev.wijsneus.app/pages/chat.vue?macro=true";
import { default as forgot_45password5PKMfJHjrcMeta } from "/home/ploi/dev.wijsneus.app/pages/forgot-password.vue?macro=true";
import { default as indexgab8LkDjZoMeta } from "/home/ploi/dev.wijsneus.app/pages/index.vue?macro=true";
import { default as loginqvo1agjmOrMeta } from "/home/ploi/dev.wijsneus.app/pages/login.vue?macro=true";
import { default as _91token_93stNE8y0MaMMeta } from "/home/ploi/dev.wijsneus.app/pages/password-reset/[token].vue?macro=true";
import { default as privacyvAJrjagbwtMeta } from "/home/ploi/dev.wijsneus.app/pages/privacy.vue?macro=true";
import { default as registerLUieYitNZtMeta } from "/home/ploi/dev.wijsneus.app/pages/register.vue?macro=true";
import { default as contactJK70OxHjBsMeta } from "/home/ploi/dev.wijsneus.app/pages/support/contact.vue?macro=true";
import { default as faq8COegBSUTDMeta } from "/home/ploi/dev.wijsneus.app/pages/support/faq.vue?macro=true";
import { default as index9xjbChibDmMeta } from "/home/ploi/dev.wijsneus.app/pages/support/index.vue?macro=true";
import { default as verifyB5FhaJP617Meta } from "/home/ploi/dev.wijsneus.app/pages/verify.vue?macro=true";
import { default as welcomensb33zUh4DMeta } from "/home/ploi/dev.wijsneus.app/pages/welcome.vue?macro=true";
export default [
  {
    name: "chat",
    path: "/chat",
    meta: chat8unszGOD0IMeta || {},
    component: () => import("/home/ploi/dev.wijsneus.app/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password5PKMfJHjrcMeta || {},
    component: () => import("/home/ploi/dev.wijsneus.app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/",
    meta: indexgab8LkDjZoMeta || {},
    component: () => import("/home/ploi/dev.wijsneus.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginqvo1agjmOrMeta || {},
    component: () => import("/home/ploi/dev.wijsneus.app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "verify.token",
    path: "/password-reset/:token()",
    meta: _91token_93stNE8y0MaMMeta || {},
    component: () => import("/home/ploi/dev.wijsneus.app/pages/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyvAJrjagbwtMeta || {},
    component: () => import("/home/ploi/dev.wijsneus.app/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerLUieYitNZtMeta || {},
    component: () => import("/home/ploi/dev.wijsneus.app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/support/contact",
    meta: contactJK70OxHjBsMeta || {},
    component: () => import("/home/ploi/dev.wijsneus.app/pages/support/contact.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/support/faq",
    meta: faq8COegBSUTDMeta || {},
    component: () => import("/home/ploi/dev.wijsneus.app/pages/support/faq.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    meta: index9xjbChibDmMeta || {},
    component: () => import("/home/ploi/dev.wijsneus.app/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "verify",
    path: "/verify",
    meta: verifyB5FhaJP617Meta || {},
    component: () => import("/home/ploi/dev.wijsneus.app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomensb33zUh4DMeta || {},
    component: () => import("/home/ploi/dev.wijsneus.app/pages/welcome.vue").then(m => m.default || m)
  }
]