import { App, type URLOpenListenerEvent } from "@capacitor/app";

export default defineNuxtPlugin(() => {
  App.addListener("appUrlOpen", function (event: URLOpenListenerEvent) {
    const router = useRouter();

    const url = new URL(event.url);

    const path = url.pathname;
    const query = Object.fromEntries(url.searchParams.entries());

    if (path || query) {
      router.push({
        path,
        query,
      });
    }
  });
});
