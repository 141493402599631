import { useChatStore } from "@/stores/chatStore";

export default defineNuxtPlugin(() => {
  const { isAuthenticated } = useSanctumAuth();

  const clearChatSettings = async () => {
    const chatStore = useChatStore();
    chatStore.clear();
  };

  onNuxtReady(async () => {
    // Clear local storage when the user logs out
    watch(
      isAuthenticated,
      async (newValue) => {
        if (!newValue) {
          clearChatSettings();
        }
      },
      { immediate: true },
    );
  });
});
