export const useSuggestions = () => {
  /**
   * Data
   */
  const sanctumFetch = useSanctumClient();

  const fetching = ref(false);

  /**
   * Methods
   */
  const index = async () => {
    fetching.value = true;

    const response = await sanctumFetch("/suggestions", {
      query: {
        limit: 5,
      },
    });

    fetching.value = false;

    return response;
  };

  return {
    fetching,
    index,
  };
};
