import type { FetchContext } from "ofetch";
import type { ConsolaInstance } from "consola";
import type { NuxtApp } from "#app";

const tokenStorageKey = "sanctum.storage.token";

export default defineAppConfig({
  text: {
    intro: {
      title: "Welkom!",
      body: "Ik ben Sofie, jouw persoonlijke assistant. Laten we beginnen!",
    },
  },
  sanctum: {
    tokenStorage: {
      get: async () => {
        if (import.meta.server) {
          return undefined;
        }

        return window.localStorage.getItem(tokenStorageKey) ?? undefined;
      },

      set: async (app: NuxtApp, token?: string) => {
        if (import.meta.server) {
          return;
        }

        if (!token) {
          window.localStorage.removeItem(tokenStorageKey);
          return;
        }

        window.localStorage.setItem(tokenStorageKey, token);
      },
    },
    interceptors: {
      onRequest: async (
        app: NuxtApp,
        ctx: FetchContext,
        logger: ConsolaInstance,
      ) => {
        const headers: HeadersInit = {
          ...ctx.options.headers,
          "X-App-Id": app.$config.public.appId,
        };
        ctx.options.headers = headers;
        logger.debug(`Added header X-App-Id: (${app.$config.public.appId})`);
      },
    },
  },
});
