import { useAppStore } from "@/stores/appStore";

export default defineNuxtPlugin(async () => {
  const sanctumFetch = useSanctumClient();

  const fetchAppData = async () => {
    const appStore = useAppStore();
    const response = await sanctumFetch("/app");
    appStore.app = response;
  };

  await fetchAppData();
});
