import { useSuggestions } from "@/composables/useSuggestions";
import { useSuggestionsStore } from "@/stores/suggestionsStore";
import { useChatStore } from "@/stores/chatStore";
import { Preferences } from "@capacitor/preferences";
import type { User } from "@/models/user";

export default defineNuxtPlugin(() => {
  const { isAuthenticated, user } = useSanctumAuth<User>();

  const { index } = useSuggestions();

  const suggestionsStore = useSuggestionsStore();

  const fetchSuggestions = async () => {
    const newSuggestions = await index();
    Object.assign(suggestionsStore.suggestions, newSuggestions);
  };

  const updateUserId = async () => {
    const oldUserId = (
      await Preferences.get({ key: "userId" })
    ).value?.toString();
    const newUserId = user.value?.id.toString();
    // Check if a new user has logged in
    if (newUserId !== oldUserId) {
      // New user logged in, clear chat history
      const chatStore = useChatStore();
      chatStore.clear();
      // Update user id in local storage
      if (newUserId) {
        await Preferences.set({
          key: "userId",
          value: newUserId,
        });
      }
    }
  };

  onNuxtReady(async () => {
    // Fetch data when the user logs in
    watch(
      isAuthenticated,
      async (newValue) => {
        if (newValue) {
          updateUserId();
          fetchSuggestions();
        }
      },
      { immediate: true },
    );
  });
});
