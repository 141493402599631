export type App = {
  id: string;
  client_id: number | undefined;
  url: string;
  assistant_id: string;
  settings: AppSettings;
};

export type AppSettings = {
  hasAutoLogin: boolean;
  hasRegistration: boolean;
  maxUserMessagesDaily: number | undefined;
  maxSpendMonthly: number | undefined;
};

export const useAppStore = defineStore("app", () => {
  const app = ref<App>({
    id: "",
    client_id: undefined,
    url: "",
    assistant_id: "",
    settings: {
      hasAutoLogin: false,
      hasRegistration: true,
      maxUserMessagesDaily: undefined,
      maxSpendMonthly: undefined,
    },
  });

  return {
    app,
  };
});
